/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@font-face {
  font-family: "SuisseIntl";
  src: url('Assets/Fonts/SuisseIntl-Regular.woff2') format('woff2'),
       url('Assets/Fonts/SuisseIntl-Regular.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: "SuisseIntl";
  src: url('Assets/Fonts/SuisseIntl-Italic.woff2') format('woff2'),
       url('Assets/Fonts/SuisseIntl-Italic.woff') format('woff');
  font-style: italic;
}
@font-face {
  font-family: "SuisseIntl-Bold";
  src: url('Assets/Fonts/SuisseIntl-Semibold.woff2') format('woff2'),
       url('Assets/Fonts/SuisseIntl-Semibold.woff') format('woff');
}

.content {
  padding-top: 50px;
}

.RedocPage-header img {
  height: 50px !important;
}
section.container__redoc {
  padding-top: 75px !important;
}
.container__redoc .menu-content{
  top: 75px !important;
}
a[rel~=noopener]{
  display: none !important;
}
.search-input::placeholder{
  color: #0099DA
}
.css-1uccc91-singleValue{
  font-family: "SuisseIntl, sans-serif";
}
.heading{
  text-align: center;
  font-family: "SuisseIntl-Bold, sans-serif";
  font-weight: 700;
  color: #003369;
  font-size: 1.7em;
  margin-top: 1em;
}
.changelog{
  margin: 20px;
}
.changelog h5{
  font-size: 1.2em;
  font-weight: 700;
  color: #1597d9;
}
.changelog h6:before{
  content: "- "
}
.changelog h6{
  font-size: 1em;
  font-weight: 600;
  color: #16c58a;
}
.changelog li, span li, div li{
  list-style: disc;
}